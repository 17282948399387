import React from "react"
import Index from "@pages/customer-protection.js";

const CustomerProtectionJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default CustomerProtectionJa
